import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStarted from 'components/GetStarted';
// import { Usecases } from 'styles/usecases';
import FeatureContainer from '../../components/FeatureContainer';
import { signUp } from '../../utils/contentProvider/function';
import 'styles/usecases.scss';
const DesignReview = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="Best Website Feedback Tool to review web designs! 🔥"
          description="Looking to streamline your design review process? Effortlessly share and collect visual feedback by leaving comments using ruttl's user-friendly platform!"
          url="https://ruttl.com/usecases/design-review"
        />
        <main className="usecases-styled-main">
          <section className="features-hero">
            <div className="container">
              <h1>Complete your design projects faster</h1>
              <p>
                Communicate, collaborate, and share design feedback seamlessly
              </p>
              <a href="https://web.ruttl.com" className="button">
                Get Started for Free
              </a>
            </div>
          </section>
          <div className="section features-main" id="features">
            <div className="container">
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/show-activities.png"
                        alt="ruttl css inspecting feature"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-30px)' }}>
                      <h2 className="feature-head">Give precise feedback</h2>
                      <p>
                        Give precise values in the form of pixel size, font
                        alignment, spacing, and make your design team’s work
                        easy and confusion-free
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Inspecting CSS
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center row-reverse-mob">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/manage-comments-separately.png"
                          alt="illustration of comments resolved after done"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        Record different versions
                      </h2>
                      <p>
                        Record different design versions for your client and
                        enable commenting for each of them to keep every
                        information in one single place
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Add Website Versions
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/leave-video-comments.png"
                          alt="illustration of video comments"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-13px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '360px' }}>
                        Say it with video
                      </h2>
                      <p style={{ maxWidth: '380px' }}>
                        It’s time to say goodbye to long emails and dozens of
                        screenshots. Let your clients and teammates record
                        detailed video comments
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Share Video Feedback
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center row-reverse-mob">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/leave-video-comments.png"
                          alt="illustration of video comments"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-13px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '360px' }}>
                        Impress your clients with custom branding
                      </h2>
                      <p style={{ maxWidth: '380px' }}>
                        Add your preferred logo, icon, primary colors, and craft
                        a personalized experience for your clients
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Try Custom Branding
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </div>
          {/* <section className="faq-main" id="faq">
              <div className="container">
                <div className="flex flex-wrap">
                  <div className="faq-heading-area">
                    <div className="sticky-heading">
                      <h2 className="heading">FAQ</h2>
                      <p>
                        Please check if your question has been answered here
                        before emailing us! We’d love to chat, but this saves
                        our time and yours!
                      </p>
                      <button
                        type="button"
                        className="button"
                        onClick={toggleContactUs}>
                        I have more questions
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 512 512">
                          <path
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="48"
                            d="M268 112l144 144-144 144M392 256H100"></path>
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="faq-question-main">
                    <Accordion allowZeroExpanded preExpanded={['a']}>
                      {[
                        {
                          id: 1,
                          uuid: 'a',
                          q: 'What happens after I make the payment?',
                          a: (
                            <>
                              Your ruttl account will be successfully upgraded
                              and it will reflect on your account.&nbsp;
                              <span className="bold">
                                Please ensure that you submit the same email
                                address with which you intend to create your
                                ruttl account, or have already created it.
                              </span>
                            </>
                          ),
                        },
                        {
                          id: 2,
                          q:
                            'For how long can I access the benefits of these privilege plans?',
                          a:
                            ' These privilege plans are available for lifetime, hence there is no expiry of their benefits once you purchase them.',
                        },
                        {
                          id: 3,
                          q:
                            'What is the difference between Collaborators and Editors?',
                          a:
                            'Collaborators are clients or guests, who will only have view and comment access inside the projects, whereas editors will have access to edit the projects and webpages both.',
                        },
                        {
                          id: 4,
                          q:
                            'What if I wish to grant edit access to a collaborator, or add an extra editor in Privilege Pro plan?',
                          a: (
                            <>
                              By default, collaborators will only have View and
                              Comment access. If edit access has to granted to
                              users after the editor number expires, it will pe
                              charged at $4 per user account.
                            </>
                          ),
                        },
                        {
                          id: 5,
                          q:
                            'What if I add an extra editor, or give edit access to a collaborator?',
                          a: (
                            <>
                              Once you add the extra editor, or give edit access
                              to a collaborator, their charge will be added to
                              your total billing, and will reflect in the next
                              month’s invoice.
                            </>
                          ),
                        },

                        {
                          id: 6,
                          q:
                            'What if I remove edit access for a collaborator or an extra editor?',
                          a:
                            'Once you remove the extra editor, or remove edit access from a collaborator, their charge will be subtracted from your total billing, and will reflect in the next month’s invoice.',
                        },
                        {
                          id: 7,
                          q:
                            'Are these privilege plans refundable or transferrable?',
                          a: (
                            <>
                              Our privilege plans are refundable within 7
                              (seven) days of purchase. All our plans are
                              non-transferrable. If you wish to refund your
                              lifetime privilege plan within 7 days, please
                              email us at&nbsp;
                              <a href="mailto:support@ruttl.com">
                                support@ruttl.com
                              </a>
                              .
                            </>
                          ),
                        },
                        {
                          id: 8,
                          q: 'What if I wish to change my privilege plan?',
                          a: (
                            <>
                              You can always upgrade your privilege plan. Simply
                              send us an email to&nbsp;
                              <a href="mailto:support@ruttl.com">
                                support@ruttl.com
                              </a>
                              &nbsp;from your ruttl registered email ID, and let
                              us know which plan would you like to upgrade to.
                              We will send you a payment link and ask you to pay
                              the difference. Usually, the upgrades are
                              processed within 2 hours, but please give us
                              atleast 8-10 hours to process your request.
                            </>
                          ),
                        },
                      ].map(({ q, a, id, uuid }) => (
                        <AccordionItem
                          uuid={uuid}
                          key={id}
                          className="faq-single">
                          <AccordionItemHeading className="question">
                            <AccordionItemButton>
                              <h4>{q}</h4>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="answer">
                            {a}
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            </section> */}
          <div className="get-started-center">
            <GetStarted toggleSignup={toggleSignup} />
          </div>
        </main>
      </>
    )}
  </Layout>
);

DesignReview.propTypes = {
  location: PropTypes.object,
};

export default DesignReview;
